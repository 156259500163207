<template>
  <ul
    v-if="exportTaskHistoryList.length"
    class="elv-common-export-task-history-info-list"
    :style="{ 'margin-top': marginTopPos, 'margin-bottom': marginBottomPos }"
  >
    <li v-for="(item, index) in exportTaskHistoryList" :key="index" class="elv-common-export-task-history-info-item">
      <div class="elv-common-export-task-history-info-item__state">
        <template v-if="item.status === 'SUCCESS'">
          <SvgIcon class="success" name="checkmark-filled" width="18" height="18" fill="#94C300" />
          {{ t('title.fileIsExportComplete', { name: t(props.exportType.label) }) }}
        </template>
        <template v-else-if="item.status === 'FAILED'">
          <SvgIcon class="success" name="sources-sync-error" width="18" height="18" fill="#94C300" />
          {{ t('title.fileIsExportFailed', { name: t(props.exportType.label) }) }}
        </template>
        <template v-else>
          <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
          {{ t('title.fileIsExporting', { name: t(props.exportType.label) }) }}
        </template>
      </div>
      <div class="elv-common-export-task-history-info-item__date">
        {{ t('common.ExportDate') }}:{{
          dayjs(item?.createdAt)
            .tz(entityStore.entityDetail?.timezone ?? 'UTC')
            .format('YYYY/MM/DD HH:mm:ss')
        }}
        {{ currentEntityTimezone }}
      </div>
      <button v-if="item.status === 'SUCCESS'" type="button" @click="generateExportTaskFileDownloadUrl(item)">
        {{ t('button.download') }}
      </button>
      <div
        v-if="item.status !== 'DOING'"
        v-loading="deletingTaskIdList.includes(item.exportTaskId)"
        class="elv-common-export-task-history-info-item__close"
      >
        <SvgIcon name="common-close" width="18" height="18" fill="#838D95" @click="onDeleteExportHistoryItem(item)" />
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import EntityApi from '@/api/EntityApi'
import { ElMessage } from 'element-plus'
import timezoneList from '@/config/timezone'
import timezone from 'dayjs/plugin/timezone'
import { downloadFile } from '@/lib/download'
import { useEntityStore } from '@/stores/modules/entity'

interface FileStatusInfo {
  exportTaskId: string
  status: string
  createdAt: string
  type: string
  deleting?: boolean
  downloadUrl?: string
  extra?: any
}

interface ExportType {
  label: string
  value: string
}

const props = defineProps({
  exportType: {
    type: Object as PropType<ExportType>,
    default: () => {
      return { label: '', value: '' } as ExportType
    },
    require: true
  },
  isMarginTop: {
    type: Boolean,
    default: false
  },
  isMarginBottom: {
    type: Boolean,
    default: true
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()

const deletingTaskIdList = ref<string[]>([])

const entityId = computed(() => {
  return route.params?.entityId as string
})

const treasuryBalancePeriodId = computed(() => {
  return String(route.params?.treasuryBalancePeriodId as string)
})

const marginTopPos = computed(() => {
  return props.isMarginTop ? '16px' : '0px'
})

const marginBottomPos = computed(() => {
  return props.isMarginBottom ? '16px' : '0px'
})

const currentEntityTimezone = computed(() => {
  return timezoneList.find((item) => item.area === entityStore.entityDetail?.timezone)?.timezone ?? 'UTC+00:00'
})

const exportTaskHistoryList = computed(() => {
  const currentExportTaskType = entityStore.exportTaskHistoryList.find((item) => item.type === props.exportType.value)
  if (props.exportType.value === 'TREASURY_BALANCE_VERIFY_TASK_PERIOD') {
    return (
      currentExportTaskType?.list?.filter(
        (item: FileStatusInfo) => item?.extra?.treasuryBalancePeriodId === treasuryBalancePeriodId.value
      ) || []
    )
  }
  return currentExportTaskType?.list || []
})

/**
 * 下载导出文件
 * @param item 导出历史记录数据
 */
const generateExportTaskFileDownloadUrl = async (item?: FileStatusInfo) => {
  try {
    if (item?.downloadUrl) {
      downloadFile(item.downloadUrl)
    }
  } catch (e: any) {
    ElMessage.error(e.message)
  }
}

/**
 * 重置导出历史操作记录
 * @param isReset 是否重置当前页导出历史记录
 */
const initExportFileStatusTipInfoList = async (isReset: boolean = false) => {
  try {
    if (isReset) {
      deletingTaskIdList.value = []
    }
    entityStore.updateExportTaskHistory(entityId.value, props.exportType.value)
  } catch (e: any) {
    ElMessage.error(e.message)
  }
}

/**
 * 删除导出历史操作记录
 * @param item 删除导出历史记录数据
 */
const onDeleteExportHistoryItem = async (item: FileStatusInfo) => {
  try {
    deletingTaskIdList.value.push(item.exportTaskId)
    await EntityApi.deleteExportTaskHistory(entityId.value, item.exportTaskId)
    initExportFileStatusTipInfoList()
    ElMessage.success(t('message.deleteSuccess'))
  } catch (e: any) {
    ElMessage.error(e.message)
  } finally {
    deletingTaskIdList.value = deletingTaskIdList.value.filter((id) => id !== item.exportTaskId)
  }
}

onMounted(() => {
  initExportFileStatusTipInfoList(true)
})
</script>

<style lang="scss" scoped>
.elv-common-export-task-history-info-list {
  width: 100%;
  box-sizing: border-box;
  padding: 0px 20px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin: 16px 0px;

  .elv-common-export-task-history-info-item {
    width: 100%;
    height: 38px;
    display: flex;
    box-sizing: border-box;
    padding: 10px 8px;
    align-items: center;
    border-radius: 2px;
    border: 1px solid #abc0f5;
    background: rgba(229, 237, 255, 0.2);
    gap: 32px;
    position: relative;

    font-family: 'Plus Jakarta Sans';
    color: #1e2024;

    .elv-common-export-task-history-info-item__state {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 13px;
      font-weight: 500;

      img {
        width: 18px;
        height: 18px;
        display: block;
        animation: loading-rotate 2s linear infinite;
      }
    }

    .elv-common-export-task-history-info-item__date {
      font-size: 12px;
      font-weight: 400;
    }

    button {
      background: none;
      border: none;
      color: #1753eb;
      font-size: 13px;
      font-weight: 400;
      text-decoration: underline;
      cursor: pointer;
    }

    .elv-common-export-task-history-info-item__close {
      position: absolute;
      right: 8px;
      cursor: pointer;

      &:hover svg {
        fill: #1343bf;
      }

      :deep(.el-loading-mask) {
        .circular {
          width: 22px;
          height: 22px;
          position: relative;
          top: 8px;
          left: -2px;
        }
      }
    }
  }
}
</style>
